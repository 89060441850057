import { Injectable, OnDestroy, inject } from '@angular/core';
import ShortUniqueId from 'short-unique-id';

// firestore functions
// DocumentData, addDoc, updateDoc, deleteDoc, DocumentReference,
import {
  Firestore,
  doc,
  getDoc,
  setDoc,
  getDocs,
  updateDoc,
  collection,
  query,
  where,
  onSnapshot,
  addDoc,
  collectionGroup,
  limit,
  deleteDoc,
  and,
  or,
  Timestamp,
} from '@angular/fire/firestore';
import {
  Functions,
  httpsCallable,
} from '@angular/fire/functions';
import { Observable, Subject } from 'rxjs';
//import { UserProfile, BusinessProfile, Business, BusinessUser} from "../interfaces/users-interface";
import {
  SellOffer,
  BuyOffer,
  BuyOrder,
  SellOrder,
  BuyContract,
  SellContract,
} from '../interfaces/orders-interface';

import { Commodities } from '../interfaces/base-interface';
import { SystemService } from './system.service';

@Injectable({
  providedIn: 'root',
})
export class OrdersService implements OnDestroy {
  private firestore: Firestore = inject(Firestore); // inject Cloud Firestore
  private functions = inject(Functions); // inject Cloud Functions

  sellOfferWatchers: any = {};
  buyOfferWatchers: any = {};
  buyOrdersWatcher: any;
  sellOrdersWatcher: any;
  myBuyOrdersWatcher: any;
  myBuyOrdersObserver!: Observable<BuyOrder[]>;
  mySellOrdersWatcher: any;
  mySellOrdersObserver!: Observable<SellOrder[]>;
  myOffersWatcher: any;
  myOffersObserver!: Observable<any[]>;

  public selectedCommodity:Commodities =  {
      commodityID:"",
      name:"",
      code:"",
      description:"",
      uom:"",
      conversions:[],
      testingOptions:[],
      acceptanceOptions:[],
      sortOrder:0,
      status:"",
      imageURL:"",
  };
  public sellOrders: SellOrder[] = [];
  public buyOrders: BuyOrder[] = [];
  public myBuyOrders: BuyOrder[] = [];
  public mySellOrders: SellOrder[] = [];
  public myOffers: any[] = [];
  public numPosts: number = 0;

  constructor(private systemService:SystemService) {}
  submit() {}

  // async testCall() {
  //   let helloWorld = httpsCallable(this.functions, 'callApiAgain');
  //   helloWorld().then(
  //     (result) => {
  //       console.log('Result of api call', result);
  //     },
  //     (err) => {
  //       console.log('Got error', err);
  //     }
  //   );
  // }


  /**
   *  updateBuyOrder will update the buyOrder with the orderID 
   * @param orderID updateBuyOrder  
   * @params orderInfo  
   * @returns 
   */ 
  updateBuyOrder(orderID: string, orderInfo: any) { 
    console.log('updateBuyOrder', orderID, orderInfo);
    // search firestore buyOrders collection for the orderID
    const buyOrdersRef = collection(this.firestore, 'buyOrders');
    const q = query(buyOrdersRef, where('orderID', '==', orderID), limit(1)); 
    // update the order with the new orderInfo
    return new Promise((resolve, reject) => { 
      getDocs(q).then( 
        (querySnapshot) => { 
          let buyOrderDocID = ''; 
          querySnapshot.forEach((doc) => { 
            buyOrderDocID = doc.id; 
          }); 
          const docpath = '/buyOrders/' + buyOrderDocID; 
          console.log('docPath: ', docpath); 
          const buyOrderRef = doc(this.firestore, docpath); 
          updateDoc(buyOrderRef, orderInfo).then( 
            (updated) => { 
              console.log('updateBuyOrder', updated); 
              resolve({ success: true, msg: 'updated the buy order' }); 
            }, 
            (err) => { 
              reject({ 
                success: false, 
                msg: 'Error in updating buyOrder', 
                error: err, 
              }); 
            } 
          ); 
        }, 
        (err) => { 
          console.log('error while updating buy order '); 
        } 
      ); 
    });
  }

/**
 * updateSellOrder will update the sellOrder with the orderID
 * @param orderID     
 * @param orderInfo 
 * @returns 
 */
  updateSellOrder(orderID: string, orderInfo: any) {
    console.log('updateSellOrder', orderID, orderInfo);
    // search firestore sellOrders collection for the orderID
    const sellOrdersRef = collection(this.firestore, 'sellOrders');
    const q = query(sellOrdersRef, where('orderID', '==', orderID), limit(1));
    // update the order with the new orderInfo
    return new Promise((resolve, reject) => {
      getDocs(q).then(
        (querySnapshot) => {
          let sellOrderDocID = '';
          querySnapshot.forEach((doc) => {
            sellOrderDocID = doc.id;
          });
          const docpath = '/sellOrders/' + sellOrderDocID;
          console.log('docPath: ', docpath);
          const sellOrderRef = doc(this.firestore, docpath);
          updateDoc(sellOrderRef, orderInfo).then(
            (updated) => {
              console.log('updateSellOrder', updated);
              resolve({ success: true, msg: 'updated the sell order' });
            },
            (err) => {
              reject({
                success: false,
                msg: 'Error in updating sellOrder',
                error: err,
              });
            }
          );
        },
        (err) => {
          console.log('error while updating sell order ');
        }
      );
    });
  }

  submitBuyOrder(orderInfo: any) {
    const uid = new ShortUniqueId({ length: 6, dictionary: 'alphanum_upper' });
    orderInfo.orderID = uid.rnd();
    orderInfo.status = 'open';
    orderInfo.createTime = new Date();
    orderInfo.updateTime = new Date();
    return new Promise((resolve, reject) => {
      const buyOrdersRef = collection(this.firestore, 'buyOrders');
      const buyOrderObject = addDoc(buyOrdersRef, orderInfo).then(
        (newOrderRef) => {
          console.log('submitBuyOrder: created new OTB doc', newOrderRef.id);
          getDoc(newOrderRef).then(
            (newOrder) => {
              console.log('submitBuyOrder: got new OTB', newOrder.id);
              let data:any = newOrder.data();
              data['tradeStart'] = data['tradeStart'].toDate();
              data['tradeEnd'] = data['tradeEnd'].toDate();
              data['deliveryDate'] = data['deliveryDate'].toDate(); 
              resolve({ success: true, orderInfo: data });
            },
            (err) => {
              reject({ success: false, error: err, orderInfo: orderInfo });
            }
          );
        },
        (err) => {
          reject({ success: false, error: err, orderInfo: orderInfo });
        }
      );
    });
  }

  submitSellOrder(orderInfo: any) {
    const uid = new ShortUniqueId({ length: 6, dictionary: 'alphanum_upper' });
    orderInfo.orderID = uid.rnd();
    orderInfo.status = 'open';
    return new Promise((resolve, reject) => {
      const sellOrdersRef = collection(this.firestore, 'sellOrders');
      const sellOrderObject = addDoc(sellOrdersRef, orderInfo).then(
        (newOrderRef) => {
          console.log('submitSellOrder: created new OTS doc', newOrderRef.id);
          getDoc(newOrderRef).then(
            (newOrder) => {
              console.log('submitSellOrder: got new OTS', newOrder.id);
              let data:any = newOrder.data();
              data['tradeStart'] = data['tradeStart'].toDate();
              data['tradeEnd'] = data['tradeEnd'].toDate();
              data['deliveryDate'] = data['deliveryDate'].toDate();
              resolve({ success: true, orderInfo: data });
            },
            (err) => {
              reject({ success: false, error: err, orderInfo: orderInfo });
            }
          );
        },
        (err) => {
          reject({ success: false, error: err, orderInfo: orderInfo });
        }
      );
    });
  }

  cancelBuyOrder(orderID: string) {
    return new Promise((resolve, reject) => {
      const buyOrdersRef = collection(this.firestore, 'buyOrders');
      const q = query(buyOrdersRef, where('orderID', '==', orderID), limit(1));
      getDocs(q).then(
        (querySnapshot) => {
          let buyOrderDocID = '';
          querySnapshot.forEach((doc) => {
            buyOrderDocID = doc.id;
          });
          const docpath = '/buyOrders/' + buyOrderDocID;
          console.log('docPath: ', docpath);
          const buyOrderRef = doc(this.firestore, docpath);

          deleteDoc(buyOrderRef).then(
            (deleted) => {
              console.log('cancelBuyOrder', deleted);
              resolve({ success: true, msg: 'deleted the buy order' });
            },
            (err) => {
              reject({
                success: false,
                msg: 'Error in deleting buyOrder',
                error: err,
              });
            }
          );
        },
        (err) => {
          console.log('error while deleting buy order ');
        }
      );
    });
  }

  cancelSellOrder(orderID: string) {
    return new Promise((resolve, reject) => {
      const sellOrdersRef = collection(this.firestore, 'sellOrders');
      const q = query(sellOrdersRef, where('orderID', '==', orderID), limit(1));
      getDocs(q).then(
        (querySnapshot) => {
          let sellOrderDocID = '';
          querySnapshot.forEach((doc) => {
            sellOrderDocID = doc.id;
          });
          const docpath = '/sellOrders/' + sellOrderDocID;
          console.log('docPath: ', docpath);
          const sellOrderRef = doc(this.firestore, docpath);

          deleteDoc(sellOrderRef).then(
            (deleted) => {
              console.log('cancelSellOrder', deleted);
              resolve({ success: true, msg: 'deleted the sell order' });
            },
            (err) => {
              reject({
                success: false,
                msg: 'Error in deleting sellOrder',
                error: err,
              });
            }
          );
        },
        (err) => {
          console.log('error while deleting sell order ');
        }
      );
    });
  }

  /**
   * The blankBuyOrder returns a blank Buy Order useful for initializing BuyOrder variables.
   * This is provided for consistency to ES6 checking
   * @returns BuyOrder
   */
  blankBuyOrder() {
    let nowDate = new Date();
    let blank = {
      orderID: '',
      buyerID: '',
      buyerBusinessID: '',
      commodity: this.systemService.blankCommodity(),
      stockpoint: this.systemService.blankStockpoint(),
      orderQuantity: 0,
      orderPrice: 0.0,
      tradePrice: 0.0,
      deliveryDate: nowDate,
      tradeStart: nowDate,
      tradeEnd: nowDate,
      testingSpecs: [],
      acceptanceSpecs: [],
      buyerRequests:[],
      buyerCharges:[],
      offers: [],
      status: ' ',
    };

    return (blank as BuyOrder);
  }

  /**
   * The blankBuyOrder returns a blank Buy Order useful for initializing BuyOrder variables.
   * This is provided for consistency to ES6 checking
   * @returns BuyOrder
   */
  blankSellOrder() {
    let nowDate = new Date();
    let blank = {
      orderID: '',
      sellerID: '',
      sellerBusinessID: '',
      commodity: this.systemService.blankCommodity(),
      stockpoint: this.systemService.blankStockpoint(),
      orderQuantity: 0,
      orderPrice: 0.0,
      tradePrice: 0.0,
      deliveryDate: nowDate,
      tradeStart: nowDate,
      tradeEnd: nowDate,
      testingSpecs: [],
      acceptanceSpecs: [],
      sellerRequests:[],
      sellerCharges:[],
      offers: [],
      status: ' ',
    };

    return (blank as SellOrder);
  }

  async getBuyOrderID(orderID: string) {
    const buyOrdersRef = collection(this.firestore, 'buyOrders');
    const q = query(buyOrdersRef, where('orderID', '==', orderID));
    const querySnapshot = await getDocs(q);
    let buyOrderID = '';
    querySnapshot.forEach((doc) => {
      buyOrderID = doc.id;
    });
    return Promise.resolve({ buyOrderDocID: buyOrderID });
  }

  async getSellOrderID(orderID: string) {
    const sellOrdersRef = collection(this.firestore, 'sellOrders');
    const q = query(sellOrdersRef, where('orderID', '==', orderID));
    const querySnapshot = await getDocs(q);
    let sellOrderID = '';
    querySnapshot.forEach((doc) => {
      sellOrderID = doc.id;
    });
    return Promise.resolve({ sellOrderDocID: sellOrderID });
  }

  blankBuyOffer(): BuyOffer {
    var blank: BuyOffer = {
      offerID: '',
      buyerID: '',
      buyerBusinessID: '',
      offerQuantity: 0,
      offerPrice: 0,
      orderID: '',
      status: 'blank',
      buyerRequests: [],
      buyerCharges: [],
    };
    return blank;
  }
  
  blankSellOffer(): SellOffer {
    var blank: SellOffer = {
      offerID: '',
      sellerID: '',
      sellerBusinessID: '',
      offerQuantity: 0,
      offerPrice: 0,
      orderID: '',
      status: 'blank',
      sellerRequests: [],
      sellerCharges: [],
    };
    return blank;
  }

  submitSellOffer(orderID: string, offerInfo: SellOffer) {
    console.log('submitSellOffer', offerInfo);
    const uid = new ShortUniqueId({ length: 6, dictionary: 'alphanum_upper' });
    offerInfo.offerID = uid.rnd();
    return new Promise((resolve, reject) => {
      this.getBuyOrderID(orderID).then(
        (result) => {
          const buyOrderDocID = result['buyOrderDocID'];
          const offersRef = collection(
            this.firestore,
            'buyOrders/' + buyOrderDocID + '/offers'
          );
          addDoc(offersRef, offerInfo).then(
            (newOfferRef) => {
              console.log('got to add a new offer', newOfferRef);
              resolve({ success: true, msg: 'made a new offer' });
            },
            (err) => {
              console.log('failed to make a new offer', err);
              reject({
                success: false,
                msg: 'failed to make a new offer',
                err: err,
              });
            }
          );
        },
        (err) => {
          console.log('error getting buyOrderID');
        }
      );
    });
  }

  submitBuyOffer(orderID: string, offerInfo: BuyOffer) {
    console.log('submitBuyOffer', offerInfo);
    const uid = new ShortUniqueId({ length: 6, dictionary: 'alphanum_upper' });
    offerInfo.offerID = uid.rnd();
    return new Promise((resolve, reject) => {
      this.getSellOrderID(orderID).then(
        (result) => {
          const sellOrderDocID = result['sellOrderDocID'];
          const offersRef = collection(
            this.firestore,
            'sellOrders/' + sellOrderDocID + '/offers'
          );
          addDoc(offersRef, offerInfo).then(
            (newOfferRef) => {
              console.log('got to add a new offer', newOfferRef);
              resolve({ success: true, msg: 'made a new offer' });
            },
            (err) => {
              console.log('failed to make a new offer', err);
              reject({
                success: false,
                msg: 'failed to make a new offer',
                err: err,
              });
            }
          );
        },
        (err) => {
          console.log('error getting sellOrderID');
        }
      );
    });
  }

  updateBuyOffer(offerID: string, offerInfo: any) {
    const offersRef = collectionGroup(this.firestore, 'offers');
    const matchOffers = query(offersRef, where('offerID', '==', offerID));
    return new Promise((resolve, reject) => {
      console.log('updateBuyOffer: find offer', offerID);
      getDocs(matchOffers).then(
        (querySnapshot) => {
          let offerDocID = '';
          var offerDocRef: any;
          console.log('updateBuyOffer, got matching offers', querySnapshot);
          querySnapshot.forEach((doc) => {
            console.log('updateBuyOffer:', doc.id, '=>', doc.data());
            offerDocRef = doc.ref;
          });
          updateDoc(offerDocRef, offerInfo).then(
            (updated) => {
              console.log('updateBuyOffer: updated sellOffer', offerDocID);
              resolve({ success: true, offerDocID: offerDocID });
            },
            (err) => {
              console.log('error while updating sellOffer', err);
              reject({ success: false, err: err });
            }
          );
        },
        (err) => {
          console.log('updateBuyOffer: error while gettingDocs', err);
        }
      );
    });
  }

  updateSellOffer(offerID: string, offerInfo: any) {
    const offersRef = collectionGroup(this.firestore, 'offers');
    const matchOffers = query(offersRef, where('offerID', '==', offerID));
    return new Promise((resolve, reject) => {
      console.log('updateSellOffer: find offer', offerID);
      getDocs(matchOffers).then((querySnapshot) => {
        let offerDocID = '';
        var offerDocRef: any;
        querySnapshot.forEach((doc) => {
          console.log('updateSellOffer:', doc.id, '=>', doc.data());
          offerDocRef = doc.ref;
        });
        updateDoc(offerDocRef, offerInfo).then(
          (updated) => {
            console.log('updateSellOffer: updated sellOffer', offerDocID);
            resolve({ success: true, offerDocID: offerDocID });
          },
          (err) => {
            console.log('error while updating sellOffer', err);
            reject({ success: false, err: err });
          }
        );
      });
    });
  }

  cancelOffer(offerID: string, orderID: string) {
    const offersRef = collectionGroup(this.firestore, 'offers');
    const matchOffers = query(
      offersRef,
      and(where('offerID', '==', offerID), where('orderID', '==', orderID))
    );
    return new Promise((resolve, reject) => {
      console.log('updateSellOffer: find offer', offerID);
      getDocs(matchOffers).then((querySnapshot) => {
        let offerDocID = '';
        var offerDocRef: any;
        querySnapshot.forEach((doc) => {
          console.log('updateSellOffer:', doc.id, '=>', doc.data());
          offerDocRef = doc.ref;
        });
        deleteDoc(offerDocRef).then(
          (updated) => {
            console.log('updateSellOffer: updated sellOffer', offerDocID);
            resolve({ success: true, offerDocID: offerDocID });
          },
          (err) => {
            console.log('error while updating sellOffer', err);
            reject({ success: false, err: err });
          }
        );
      });
    });
  }

  async getAllBuyOffers(orderID: string): Promise<Observable<any[]>> {
    const { sellOrderDocID } = await this.getSellOrderID(orderID);
    const offersList = new Subject<any[]>();

    console.log('getAllBuyOffers: sellOrderID', sellOrderDocID);
    console.log('getAllBuyOffers', 'sellOrders/' + sellOrderDocID + '/offers');
    const buyOffersRef = collection(
      this.firestore,
      'sellOrders/' + sellOrderDocID + '/offers'
    );

    const offersSubscription = onSnapshot(buyOffersRef, (offers) => {
      console.log('getAllSellOffers: new snapshot', offers);
      const data = offers.docs.map((doc) =>
        Object.assign({}, doc.data(), { buyOfferID: doc.id })
      );
      offersList.next(data);
    });
    this.buyOfferWatchers[orderID] = offersSubscription;
    return offersList.asObservable();
  }

  async getAllSellOffers(orderID: string): Promise<Observable<any[]>> {
    const { buyOrderDocID } = await this.getBuyOrderID(orderID);
    const offersList = new Subject<any[]>();

    console.log('getAllSellOffers: buyOrderID', buyOrderDocID);
    console.log('getAllSellOffers', 'buyOrders/' + buyOrderDocID + '/offers');
    const sellOffersRef = collection(
      this.firestore,
      'buyOrders/' + buyOrderDocID + '/offers'
    );

    const offersSubscription = onSnapshot(sellOffersRef, (offers) => {
      console.log('getAllSellOffers: new snapshot', offers);
      const data = offers.docs.map((doc) =>
        Object.assign({}, doc.data(), { sellOfferID: doc.id })
      );
      offersList.next(data);
    });
    this.sellOfferWatchers[orderID] = offersSubscription;
    return offersList.asObservable();
  }

  unsubscribeSellOffers(orderID: string) {
    const unsubscribe = this.sellOfferWatchers[orderID];
    unsubscribe();
  }

  async getAllBuyOrders(code: string) {
    console.log('getAllBuyOrders', code);
    let getAllBuys = httpsCallable(this.functions, 'doGetAllBuyOrders');
    let response = await getAllBuys({ commodity: code });
    console.log('Result doGetAllBuyOrders', response.data);
    return response.data;
  }

  async watchAllBuyOrders(codeList: string[], status:string=""): Promise<Observable<any[]>> {
    const allBuyOrders = new Subject<any[]>();
    // unsubscribe if there is an existing watcher
    if(this.buyOrdersWatcher!== undefined){
      this.buyOrdersWatcher();
    };
    this.buyOrders = [];
    console.log('watchAllBuyOrders', codeList);
    const buyOrdersRef = collection(this.firestore, 'buyOrders');
    // if status != "" set query to also filter by status
    let q;
    let cutoff = new Date();
    // set the cutoff time to 3:00PM
    cutoff.setHours(15,0,0,0);
    if(status!=""){
      q = query(buyOrdersRef, where('commodity.code', 'in', codeList),  where('status', '==', status)); //where('tradeEnd', '>=', cutoff),
    } else {
      q = query(buyOrdersRef, where('commodity.code', 'in', codeList));  //, where('tradeEnd', '>=', cutoff)
    }
    const buyOrdersSubscription = onSnapshot(q, (buyOrders) => {
      console.log('watchAllBuyOrders: new snapshot', buyOrders);
      const data = buyOrders.docs.map((doc) => doc.data());
      data.forEach((order, index) => {
        //convert tradeStart, tradeEnd, deliveryDate to Date
        data[index]['tradeStart'] = order['tradeStart'].toDate();
        data[index]['tradeEnd'] = order['tradeEnd'].toDate();
        data[index]['deliveryDate'] = order['deliveryDate'].toDate(); 
      })
      this.buyOrders = (data as BuyOrder[]);
      allBuyOrders.next(data);
    });
    this.buyOrdersWatcher = buyOrdersSubscription;
    return allBuyOrders.asObservable();
  }

  async watchAllSellOrders(codeList: string[], status:string=""): Promise<Observable<any[]>> {
    const allSellOrders = new Subject<any[]>();
    // unsubscribe if there is an existing watcher
    if(this.sellOrdersWatcher!== undefined){
      this.sellOrdersWatcher();
    }
    this.sellOrders=[];
    console.log('watchAllSellOrders', codeList);
    const sellOrdersRef = collection(this.firestore, 'sellOrders');
    // if status != "" set query to also filter by status
    let q;
    let cutoff = new Date();
    // set the cutoff time to 3:00PM
    cutoff.setHours(15,0,0,0);
    if(status!=""){
      q = query(sellOrdersRef, where('commodity.code', 'in', codeList), where('tradeEnd', '>=', cutoff), where('status', '==', status));
    } else {
      q = query(sellOrdersRef, where('commodity.code', 'in', codeList), where('tradeEnd', '>=', cutoff));
    }
    const sellOrdersSubscription = onSnapshot(q, (sellOrders) => {
      console.log('watchAllSellOrders: new snapshot', sellOrders);
      const data = sellOrders.docs.map((doc) => doc.data());
      data.forEach((order, index) => {
        //convert tradeStart, tradeEnd, deliveryDate to Date
        data[index]['tradeStart'] = order['tradeStart'].toDate();
        data[index]['tradeEnd'] = order['tradeEnd'].toDate();
        data[index]['deliveryDate'] = order['deliveryDate'].toDate(); 
      })
      console.log('watchAllSellOrders: updated dates', data);
      this.sellOrders = (data as SellOrder[]);
      allSellOrders.next(data);
    });
    this.sellOrdersWatcher = sellOrdersSubscription;
    return allSellOrders.asObservable();
  }

  async watchAllMyBuyOrders(
    buyerBusinessID: string
  ): Promise<Observable<any[]>> {
    const allBuyOrders = new Subject<any[]>();

    console.log('watchAllMyBuyOrders', buyerBusinessID);
    const buyOrdersRef = collection(this.firestore, 'buyOrders');
    const q = query(
      buyOrdersRef,
      where('buyerBusinessID', '==', buyerBusinessID)
    );
    const buyOrdersSubscription = onSnapshot(q, (buyOrders) => {
      console.log('watchAllMyBuyOrders: new snapshot', buyOrders);
      const data = buyOrders.docs.map((doc) => doc.data());
      data.forEach((order, index) => {
        //convert tradeStart, tradeEnd, deliveryDate to Date
        data[index]['tradeStart'] = order['tradeStart'].toDate();
        data[index]['tradeEnd'] = order['tradeEnd'].toDate();
        data[index]['deliveryDate'] = order['deliveryDate'].toDate(); 
      })
      this.myBuyOrders = (data as BuyOrder[]);
      this.setNumPosts();
      allBuyOrders.next(data);
    });
    this.myBuyOrdersWatcher = buyOrdersSubscription;
    this.myBuyOrdersObserver = allBuyOrders.asObservable();
    return allBuyOrders.asObservable();
  }

  async watchAllMySellOrders(
    sellerBusinessID: string
  ): Promise<Observable<any[]>> {
    const allSellOrders = new Subject<any[]>();

    console.log('watchAllMySellOrders', sellerBusinessID);
    const sellOrdersRef = collection(this.firestore, 'sellOrders');
    const q = query(
      sellOrdersRef,
      where('sellerBusinessID', '==', sellerBusinessID)
    );
    const sellOrdersSubscription = onSnapshot(q, (sellOrders) => {
      console.log('watchAllMySellOrders: new snapshot', sellOrders);
      const data = sellOrders.docs.map((doc) => doc.data());
      data.forEach((order, index) => {
        //convert tradeStart, tradeEnd, deliveryDate to Date
        data[index]['tradeStart'] = order['tradeStart'].toDate();
        data[index]['tradeEnd'] = order['tradeEnd'].toDate();
        data[index]['deliveryDate'] = order['deliveryDate'].toDate(); 
      })
      this.mySellOrders = (data as SellOrder[]);
      this.setNumPosts();
      allSellOrders.next(data);
    });
    this.mySellOrdersWatcher = sellOrdersSubscription;
    this.mySellOrdersObserver = allSellOrders.asObservable();
    return allSellOrders.asObservable();
  }

  setNumPosts(){
    this.numPosts = this.myBuyOrders.length + this.mySellOrders.length + this.myOffers.length;
  }

  getAllSellOrders(commodity: string) {
    console.log('getAllSellOrders', commodity);
  }

  ngOnDestroy() {
    // when manually subscribing to an observable remember to unsubscribe in ngOnDestroy
  }

  /** Contract statii
   * 0 - new - new - for acceptance, not yet a fully signed contract
   * 1 - pending - forPending - contracted, but delivery date is still far away
   * 2 - payment - forPayment - buyer has to initiate payment prior to delivery by seller to stockpoint
   * 3 - receiving - forReceiving - order has been received by stockpoint
   * 4 - shipping - forShipping - lot is ready to be shipped to buyer
   * 5 - closed - fulfilled - lot has been shipped to buyer
   */
  /**
   * blankBuyContract creates a blankBuyContract
   * useful when creating the variable but not yet defining it
   * this is a utility function for ESLint compliance
   * @returns a blank Buy contract
   */

  blankBuyContract(): BuyContract {
    var blank: BuyContract = {
      contractID: '',
      orderID: '',
      buyerID: '',
      buyerBusinessID: '',
      sellerID: '',
      sellerBusinessID: '',
      commodity: this.systemService.blankCommodity(),
      contractQuantity: 0,
      contractPrice: 0,
      deliveryDate: new Date(),
      stockpoint: this.systemService.blankStockpoint(),
      testingSpecs: [],
      acceptanceSpecs: [],
      sellerRequests:[],
      sellerCharges: [],
      buyerRequests:[],
      buyerCharges: [],
      lakalCharges: [],
      paymentTerms: '',

      status: 'blank',
    };
    return blank;
  }

  blankSellContract(): SellContract {
    var blank: BuyContract = {
      contractID: '',
      orderID: '',
      buyerID: '',
      buyerBusinessID: '',
      sellerID: '',
      sellerBusinessID: '',
      commodity: this.systemService.blankCommodity(),
      contractQuantity: 0,
      contractPrice: 0,
      deliveryDate: new Date(),
      stockpoint: this.systemService.blankStockpoint(),
      testingSpecs: [],
      acceptanceSpecs: [],
      sellerRequests:[],
      sellerCharges: [],
      buyerRequests:[],
      buyerCharges: [],
      lakalCharges: [],
      paymentTerms: '',
      status: 'blank',
    };
    return blank;
  }

  /**
   * Draft Buy Contract will create a BuyContract object from an offer.
   * It is intended to be used or initate only by a buyer of an OTB. A Market Maker.
   * The output of the BuyContract is intended to be sent to createBuyContract
   * @param orderInfo
   * @param offerInfo
   * @returns
   */
  draftBuyContract(orderInfo: BuyOrder, offerInfo: SellOffer): BuyContract {
    var draft: BuyContract = {
      contractID: orderInfo.orderID + '-' + offerInfo.offerID,
      orderID: orderInfo.orderID,
      buyerID: orderInfo.buyerID,
      buyerBusinessID: orderInfo.buyerBusinessID,
      sellerID: offerInfo.sellerID,
      sellerBusinessID: offerInfo.sellerBusinessID,
      commodity: orderInfo.commodity,
      contractQuantity: offerInfo.offerQuantity,
      contractPrice: offerInfo.offerPrice,
      deliveryDate: orderInfo.deliveryDate,
      stockpoint: orderInfo.stockpoint,
      testingSpecs: orderInfo.testingSpecs,
      acceptanceSpecs: orderInfo.acceptanceSpecs,
      sellerRequests:offerInfo.sellerRequests,
      sellerCharges: offerInfo.sellerCharges,
      buyerRequests:orderInfo.buyerRequests || [],
      buyerCharges: orderInfo.buyerCharges || [],
      lakalCharges: [],
      paymentTerms: '',
      status: 'draft',
    };
    return draft;
  }

  /**
   * Draft Sell Contract will create a SellContract object from an offer.
   * It is intended to be used or initate only by a buyer of an OTB. A Market Maker.
   * The output of the SellContract is intended to be sent to createSellContract
   * @param orderInfo
   * @param offerInfo
   * @returns
   */

  draftSellContract(orderInfo: SellOrder, offerInfo: BuyOffer): SellContract {
    var draft: SellContract = {
      contractID: orderInfo.orderID + '-' + offerInfo.offerID,
      orderID: orderInfo.orderID,
      sellerID: orderInfo.sellerID,
      sellerBusinessID: orderInfo.sellerBusinessID,
      buyerID: offerInfo.buyerID,
      buyerBusinessID: offerInfo.buyerBusinessID,
      commodity: orderInfo.commodity,
      contractQuantity: offerInfo.offerQuantity,
      contractPrice: offerInfo.offerPrice,
      deliveryDate: orderInfo.deliveryDate,
      stockpoint: orderInfo.stockpoint,
      testingSpecs: orderInfo.testingSpecs,
      acceptanceSpecs: orderInfo.acceptanceSpecs,
      sellerRequests:orderInfo.sellerRequests || [],
      sellerCharges: orderInfo.sellerCharges || [],
      buyerRequests:offerInfo.buyerRequests,
      buyerCharges: offerInfo.buyerCharges,
      lakalCharges: [],
      paymentTerms: '',
      status: 'draft',
    };
    return draft;
  }

  /**
   * Create Buy Contract will take a BuyContract object and simply save it to firestore
   * @param buyContract
   * @returns
   */
  createBuyContract(buyContract: BuyContract): Promise<any> {
    console.log('createBuyContract');
    return new Promise((resolve, reject) => {
      const buyContractsRef = collection(this.firestore, 'buyContracts');
      const buyContractObject = addDoc(buyContractsRef, buyContract).then(
        (newDocRef) => {
          console.log('created new BuyContract', newDocRef.id);
          getDoc(newDocRef).then(
            (newBuyContract) => {
              console.log('got newBuyContractID', newBuyContract.id);
              resolve({
                success: true,
                buyContractID: newBuyContract.id,
                buyContract: newBuyContract.data(),
              });
            },
            (err) => {
              console.log('could not retrieve new contract');
              reject({
                success: false,
                msg: 'could not get new buy contract',
                err: err,
              });
            }
          );
        }
      );
    });
  }

  /**
   * Create Sell Contract will take a SellContract object and simply save it to firestore
   * @param sellContract
   * @returns
   */
  createSellContract(sellContract: SellContract): Promise<any> {
    console.log('createSellContract');
    return new Promise((resolve, reject) => {
      const sellContractsRef = collection(this.firestore, 'sellContracts');
      const sellContractObject = addDoc(sellContractsRef, sellContract).then(
        (newDocRef) => {
          console.log('created new SellContract', newDocRef.id);
          getDoc(newDocRef).then(
            (newSellContract) => {
              console.log('got newSellContractID', newSellContract.id);
              resolve({
                success: true,
                sellContractID: newSellContract.id,
                sellContract: newSellContract.data(),
              });
            },
            (err) => {
              console.log('could not retrieve new contract');
              reject({
                success: false,
                msg: 'could not get new sell contract',
                err: err,
              });
            }
          );
        }
      );
    });
  }

  /**
   * getBuyContract will retrieve a buyContract from firestore using the orderID and offerID
   * @param orderInfo
   * @param offerInfo
   * @returns
   */
  getBuyContract(orderID: string, offerID: string): Promise<any> {
    const contractID = orderID + '-' + offerID;
    const buyContractsRef = collection(this.firestore, 'buyContracts');
    const q = query(buyContractsRef, where('contractID', '==', contractID));
    return new Promise((resolve, reject) => {
      getDocs(q).then(
        (buyContractsSnapshot) => {
          let buyContract:any = {};
          buyContractsSnapshot.forEach((doc) => {
            buyContract = Object.assign({}, doc.data(), {
              contractDocID: doc.id,
            });
          });
         // convert signedBuyer and signedSeller to Date objects
         if (buyContract['signedBuyer']) {
            buyContract.signedBuyer = buyContract.signedBuyer.toDate();
          }
          if (buyContract['signedSeller']) {
            buyContract.signedSeller = buyContract.signedSeller.toDate();
          }
          resolve({ success: true, buyContract: buyContract });
        },
        (err) => {
          console.log('error while getting buyContract');
          reject({ success: false, error: err });
        }
      );
    });
  }

  getSellContract(orderID: string, offerID: string): Promise<any> {
    const contractID = orderID + '-' + offerID;
    const sellContractsRef = collection(this.firestore, 'sellContracts');
    console.log("getSellContract", contractID);
    const q = query(sellContractsRef, where('contractID', '==', contractID));
    return new Promise((resolve, reject) => {
      getDocs(q).then(
        (sellContractsSnapshot) => {
          let sellContract:any = {};
          sellContractsSnapshot.forEach((doc) => {
            sellContract = Object.assign({}, doc.data(), {
              contractDocID: doc.id,
            });
          });

          // convert signedBuyer and signedSeller to Date objects
          if (sellContract.signedBuyer) {
            sellContract.signedBuyer = sellContract.signedBuyer.toDate();
          }
          if (sellContract.signedSeller) {
            sellContract.signedSeller = sellContract.signedSeller.toDate();
          }
          resolve({ success: true, sellContract: sellContract });
        },
        (err) => {
          console.log('error while getting sellContract');
          reject({ success: false, error: err });
        }
      );
    });
  }

  /**
   * Buyer will now sign the BuyContract with BuyContractID
   * Buyer is the market maker, final signer makes the contract pending
   * @param buyContractID
   * @returns
   */
  buyerSignBuyContract(buyContractID: string, params:any): Promise<any> {
    return new Promise((resolve, reject) => {
      const buyContractRef = doc(
        this.firestore,
        'buyContracts/' + buyContractID
      );
      updateDoc(buyContractRef, {
        signedBuyer: new Date(),
        status: 'pending',
        ...params
      }).then(
        (result) => {
          console.log(
            'buyerSignBuyContract: updated BuyContract',
            buyContractID,
            result
          );
          resolve({ success: true, signed: result });
        },
        (err) => {
          console.log(
            'buyerSignBuyContract: error while updating BuyContract',
            buyContractID,
            err
          );
          reject({
            success: false,
            msg: 'error while signing buyContract',
            err: err,
          });
        }
      );
    });
  }

  /**
   * Seller will now sign the BuyContract with BuyContractID
   * Seller is the market taker, but signs the contract first so contract is open
   * @param buyContractID
   * @returns
   */
  sellerSignBuyContract(buyContractID: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const buyContractRef = doc(
        this.firestore,
        'buyContracts/' + buyContractID
      );
      updateDoc(buyContractRef, {
        signedSeller: new Date(),
        status: 'open',
      }).then(
        (result) => {
          console.log(
            'sellerSignBuyContract: updated BuyContract',
            buyContractID,
            result
          );
          resolve({ success: true, signed: result });
        },
        (err) => {
          console.log(
            'sellerSignBuyContract: error while updating BuyContract',
            buyContractID,
            err
          );
          reject({
            success: false,
            msg: 'error while signing buyContract',
            err: err,
          });
        }
      );
    });
  }

  /**
   * Seller will now sign the SellContract with SellContractID
   * Seller is the market maker, so contract is final when seller signs, contract becomes pending
   * @param sellContractID
   * @returns
   */
  sellerSignSellContract(sellContractID: string, params:any): Promise<any> {
    return new Promise((resolve, reject) => {
      const sellContractRef = doc(
        this.firestore,
        'sellContracts/' + sellContractID
      );
      updateDoc(sellContractRef, {
        signedSeller: new Date(),
        status: 'pending',
        ...params
      }).then(
        (result) => {
          console.log(
            'sellerSignSellContract: updated SellContract',
            sellContractID,
            result
          );
          resolve({ success: true, signed: result });
        },
        (err) => {
          console.log(
            'sellerSignSellContract: error while updating SellContract',
            sellContractID,
            err
          );
          reject({
            success: false,
            msg: 'error while signing SellContract',
            err: err,
          });
        }
      );
    });
  }

  /**
   * Buyer will now sign the SellContract with SellContractID
   * Buyer is the market taker, but signs the contract first, contract is open
   * @param sellContractID
   * @returns
   */
  buyerSignSellContract(sellContractID: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const sellContractRef = doc(
        this.firestore,
        'sellContracts/' + sellContractID
      );
      updateDoc(sellContractRef, {
        signedBuyer: new Date(),
        status: 'open',
      }).then(
        (result) => {
          console.log(
            'buyerSignSellContract: updated SellContract',
            sellContractID,
            result
          );
          resolve({ success: true, signed: result });
        },
        (err) => {
          console.log(
            'buyerSignSellContract: error while updating SellContract',
            sellContractID,
            err
          );
          reject({
            success: false,
            msg: 'error while signing SellContract',
            err: err,
          });
        }
      );
    });
  }

  cancelBuyContract(contractID: string) {
    return new Promise((resolve, reject) => {
      const buyContractsRef = collection(this.firestore, 'buyContracts');
      const q = query(
        buyContractsRef,
        where('contractID', '==', contractID),
        limit(1)
      );
      getDocs(q).then(
        (querySnapshot) => {
          let buyContractDocID = '';
          querySnapshot.forEach((doc) => {
            buyContractDocID = doc.id;
          });
          const docpath = '/buyContracts/' + buyContractDocID;
          console.log('docPath: ', docpath);
          const buyContractRef = doc(this.firestore, docpath);

          deleteDoc(buyContractRef).then(
            (deleted) => {
              console.log('cancelBuyContract', deleted);
              resolve({ success: true, msg: 'deleted the buy contract' });
            },
            (err) => {
              reject({
                success: false,
                msg: 'Error in deleting buyContract',
                error: err,
              });
            }
          );
        },
        (err) => {
          console.log('error while deleting buy contract ');
        }
      );
    });
  }
  cancelSellContract(contractID: string) {
    return new Promise((resolve, reject) => {
      const sellContractsRef = collection(this.firestore, 'sellContracts');
      const q = query(
        sellContractsRef,
        where('contractID', '==', contractID),
        limit(1)
      );
      getDocs(q).then(
        (querySnapshot) => {
          let sellContractDocID = '';
          querySnapshot.forEach((doc) => {
            sellContractDocID = doc.id;
          });
          const docpath = '/sellContracts/' + sellContractDocID;
          console.log('docPath: ', docpath);
          const sellContractRef = doc(this.firestore, docpath);

          deleteDoc(sellContractRef).then(
            (deleted) => {
              console.log('cancelSellContract', deleted);
              resolve({ success: true, msg: 'deleted the sell contract' });
            },
            (err) => {
              reject({
                success: false,
                msg: 'Error in deleting sellContract',
                error: err,
              });
            }
          );
        },
        (err) => {
          console.log('error while deleting sell contract ');
        }
      );
    });
  }

  sellerUpdateBuyContract(contractID: string, params:any) {
    return new Promise((resolve, reject) => {
      const buyContractsRef = collection(this.firestore, 'buyContracts');
      const q = query(
        buyContractsRef,
        and(where('contractID', '==', contractID),where('status', '==', "open")),
        limit(1)
      );
      getDocs(q).then(
        (querySnapshot) => {
          let buyContractDocID = '';
          querySnapshot.forEach((doc) => {
            buyContractDocID = doc.id;
          });
          const docpath = '/buyContracts/' + buyContractDocID;
          console.log('docPath: ', docpath);
          const buyContractRef = doc(this.firestore, docpath);

          updateDoc(buyContractRef, params).then(
            (updated) => {
              console.log('sellerUpdateBuyContract', updated);
              resolve({ success: true, msg: 'updated the buy contract' });
            },
            (err) => {
              reject({
                success: false,
                msg: 'Error in updating buyContract',
                error: err,
              });
            }
          );
        },
        (err) => {
          console.log('error while updating buy contract ');
        }
      );
    });
  }

  buyerUpdateSellContract(contractID: string, params:any) {
    console.log('buyerUpdateSellContract', contractID, params);
    return new Promise((resolve, reject) => {
      const sellContractsRef = collection(this.firestore, 'sellContracts');
      const q = query(
        sellContractsRef,
        and(where('contractID', '==', contractID),where('status', '==', "open")),
        limit(1)
      );
      getDocs(q).then(
        (querySnapshot) => {
          let sellContractDocID = '';
          querySnapshot.forEach((doc) => {
            sellContractDocID = doc.id;
          });
          const docpath = '/sellContracts/' + sellContractDocID;
          console.log('docPath: ', docpath);
          const sellContractRef = doc(this.firestore, docpath);

          updateDoc(sellContractRef, params).then(
            (updated) => {
              console.log('buyerUpdateSellContract', updated);
              resolve({ success: true, msg: 'updated the sell contract' });
            },
            (err) => {
              reject({
                success: false,
                msg: 'Error in updating sellContract',
                error: err,
              });
            }
          );
        },
        (err) => {
          console.log('error while updating sell contract ');
        }
      );
    });
  }

  getAllMyOffers(userID: string) {
    console.log('getAllMyOffers', userID);
    const offersRef = collectionGroup(this.firestore, 'offers');
    const myOffers = query(offersRef, where('sellerID', '==', userID));
    return new Promise((resolve, reject) => {
      console.log('getAllMyOffers run query');
      getDocs(myOffers).then(
        (querySnapshot) => {
          let sellOffers: any[] = [];
          let buyOffers: any[] = [];
          querySnapshot.forEach((doc) => {
            console.log(doc.id, '=>');
            let offer = doc.data();
            if (offer['sellerID'] !== undefined) {
              sellOffers.push(
                Object.assign({}, offer, { sellOfferID: doc.id })
              );
            } else {
              buyOffers.push(Object.assign({}, offer, { buyOfferID: doc.id }));
            }
          });
          resolve({
            success: true,
            sellOffers: sellOffers,
            buyOffers: buyOffers,
          });
        },
        (err) => {
          console.log('getAllMyOffers: did not get any', err);
          reject({ success: false, err: err });
        }
      );
    });
  }

  async watchAllMyOffers(businessID: string) {
    console.log('getAllMyOffers', businessID);
    const allOffers = new Subject<any[]>();
    const offersRef = collectionGroup(this.firestore, 'offers');
    const myOffers = query(offersRef,or (where('sellerBusinessID', '==', businessID), where('buyerBusinessID', '==', businessID)));

    const myOffersSubscription = onSnapshot(myOffers, (offers) => {
      console.log('watchAllMyOffers: new snapshot', offers);
      const data = offers.docs.map((doc) => doc.data());
      data.forEach((offer, index) => {
        if (offer['sellerID'] !== undefined) {
          data[index]['offerType'] = 'sell';
        } else {
          data[index]['offerType'] = 'buy';
        }
      });
      this.myOffers = data;
      this.setNumPosts();
      allOffers.next(data);
    });

    this.myOffersWatcher = myOffersSubscription;
    this.myOffersObserver = allOffers.asObservable();
    return allOffers.asObservable();
  }

  getBuyOrderByID(orderID: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const buyOrdersRef = collection(this.firestore, 'buyOrders');
      const q = query(buyOrdersRef, where('orderID', '==', orderID), limit(1));
      getDocs(q).then(
        (querySnapshot) => {
          let buyOrderDocID = '';
          querySnapshot.forEach((doc) => {
            buyOrderDocID = doc.id;
          });
          const docpath = '/buyOrders/' + buyOrderDocID;
          console.log('docPath: ', docpath);
          const buyOrderRef = doc(this.firestore, docpath);
          getDoc(buyOrderRef).then(
            (buyOrder) => {
              let data:any = buyOrder.data();
              data['tradeStart'] = data['tradeStart'].toDate();
              data['tradeEnd'] = data['tradeEnd'].toDate();
              data['deliveryDate'] = data['deliveryDate'].toDate(); 
              resolve({
                success: true,
                msg: 'got the buy order',
                buyOrder: data,
              });
            },
            (err) => {
              reject({
                success: false,
                msg: 'Error in getting buyOrder',
                error: err,
              });
            }
          );
        },
        (err) => {
          console.log('error while getting docs ');
        }
      );
    });
  }

  getSellOrderByID(orderID: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const sellOrdersRef = collection(this.firestore, 'sellOrders');
      const q = query(sellOrdersRef, where('orderID', '==', orderID), limit(1));
      getDocs(q).then(
        (querySnapshot) => {
          let sellOrderDocID = '';
          querySnapshot.forEach((doc) => {
            sellOrderDocID = doc.id;
          });
          const docpath = '/sellOrders/' + sellOrderDocID;
          console.log('docPath: ', docpath);
          const sellOrderRef = doc(this.firestore, docpath);
          getDoc(sellOrderRef).then(
            (sellOrder) => {
              let data:any = sellOrder.data();
              data['tradeStart'] = data['tradeStart'].toDate();
              data['tradeEnd'] = data['tradeEnd'].toDate();
              data['deliveryDate'] = data['deliveryDate'].toDate(); 
              resolve({
                success: true,
                msg: 'got the sell order',
                sellOrder: data
              });
            },
            (err) => {
              reject({
                success: false,
                msg: 'Error in getting sellOrder',
                error: err,
              });
            }
          );
        },
        (err) => {
          console.log('error while getting docs ');
        }
      );
    });
  }
}
